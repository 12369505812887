import React, { useContext, useEffect, useState, useRef } from "react";
import Navbar from "../Navbar/Navbar";
import "./section.css";

import group6 from "../../assets/Images/Group 6.png";
import group7 from "../../assets/Images/Group 7.png";
import group9 from "../../assets/Images/Group 9.png";
import group10 from "../../assets/Images/Group 10.png";

import Data from "./Data";

import InformationBox from "./InformationBox";
import SectionRightSideContent from "./SectionRightSideContent";
import ApplyButton from "../ApplyButton";
import GetStartedButton from "../GetStartedButton";

const images = [
  {
    src: group6,
    title: "Raw Material",
  },
  { src: group7, title: "Project" },
  { src: group9, title: "Machinery" },
  { src: group10, title: "Supply Chain" },
];

const Section = ({ setValue, value, showProductMenu, setShowProductMenu }) => {
  const [sectionData] = useState(Data);



  const [currentIndex, setCurrentIndex] = useState(0);
  const [currentValue, setCurrentValue] = useState(0);

  const {
    heading,
    para,
    col3,
    buttons,
    textWrap,
    image,
    position,
  } = sectionData[value];

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentIndex((currentIndex + 1) % images?.length);
      setCurrentValue((currentValue) =>
        currentValue === 5 ? 0 : currentValue + 1
      );
    }, 3000);

    return () => clearInterval(intervalId);
  }, [currentIndex, currentValue]);

  const styles = {
    fontSize: value === 0 ? "3.664375rem" : "7rem",
    lineHeight: value === 2 ? "6.25rem" : "6.4rem",
    textWrap: textWrap ? "wrap" : "nowrap",
  };

  return (
    <section className="section1">
      <div
        className={`section-1-container ${heading.replace(/ /g, "")}Section`}
      >
        <Navbar
          setValue={setValue}
          value={value}
          showProductMenu={showProductMenu}
          setShowProductMenu={setShowProductMenu}
        />

        <div className={`sectionContent ${heading.replace(/ /g, "")}`}>
          <div className='leftContent'>
            <div className="leftContent-header">
              <h1 style={styles} className="heading">
                {heading}
              </h1>
              <p className="para">{para}</p>
            </div>
            <div style={{ display: col3 ? "flex" : "none" }} className="col4">
              <div className="row row1">
                <b >₹ 4000 Cr+</b>
                <p>Amount Disbursed</p>
              </div>
              <div className="row row2">
                <b>30+</b>
                <p>Banks & NBFCs</p>
              </div>
              <div className="row row3">
                <b>10 Lakhs+</b>
                <p>B2B Transactions</p>
              </div>
            </div>
          </div>

          {value === 0 && (
            <SectionRightSideContent
              currentValue={currentValue}
              images={images}
              currentIndex={currentIndex}
            />
          )}

          {value !== 0 && (
            <div className='imageContainer'>
              <img
                className="dynamicImage"
                style={{
                    right: position?.right }}
                src={image}
                alt=""
              />
            </div>
          )}
        </div>
        {value !== 0 && (
          <div className="informationBox">
            <InformationBox />
          </div>
        )}
        <div className="col5">
          {value === 0 && <GetStartedButton/>}
          {value !== 0 && <ApplyButton/>}

          {buttons.btn2 === 'OUR PRODUCTS' && <button 
          onClick={() => setValue(1)} className="ourProducts">
            {buttons?.btn2}
          </button>}

          {buttons.btn2 !== 'OUR PRODUCTS' && <a href="https://b24-ynfsx4.bitrix24.site/crm_form_nprvo/" target="_blank" 
           className="ourProducts">
            {buttons?.btn2}
          </a>}
        </div>
      </div>
    </section>
  );
};

export default Section;
