import Image1 from "../../assets/Images/Group 14787.png";
import Image2 from "../../assets/Images/Group 14804.png";
import Image3 from "../../assets/Images/Group 14789.png";
import Image4 from "../../assets/Images/Group 14788.png";
import Image5 from "../../assets/Images/Group 14803.png";
import Image6 from "../../assets/Images/Group 14786.png";

const Data = () => [
  {
    heading: "Growth Ke Liye Expert Advice",
    para: "One-stop business financing platform for MSMEs, Startups & Large Enterprises",
    col3: true,
    buttons: {
      btn1: "GET STARTED",
      btn2: "OUR PRODUCTS",
    },
    textWrap: true,
    image: "",
    position: {
      right: 0,
      bottom: 0,
    },
  },
  {
    heading: "Purchase Finance",
    para: "Procure raw materials for your business at the right price with our working capital finance solution",
    col3: false,
    buttons: {
      btn1: "APPLY NOW",
      btn2: "CHECK ELIGIBILITY",
    },
    textWrap: false,
    image: Image6,
    position: {
      right: "-2.625rem",
      bottom: 0,
    },
    
  },
  {
    heading: "Lease Rental Discounting",
    para: "Lease rental discounting (LRD) is a term loan provided based on rental income. ",
    col3: false,
    buttons: {
      btn1: "APPLY NOW",
      btn2: "CHECK ELIGIBILITY",
    },
    textWrap: true,
    image: Image1,
    position: {
      right: 0,
      bottom: 0,
    },
    
  },

  {
    heading: "Invoice Discounting",
    para: "Invoice discounting is a short-term loan provided against unpaid invoices. You can utilise invoice discounting  for outstanding invoices that will be paid in the future.",
    col3: false,
    buttons: {
      btn1: "APPLY NOW",
      btn2: "CHECK ELIGIBILITY",
    },
    textWrap: false,
    image: Image4,
    position: {
      right: '-7rem',
      bottom: 0,
    },
    
  },
  {
    heading: "Working Capital",
    para: "Working capital loan is a short-term loan designed to cover a company's day-to-day operational expenses. The loan amount is based on the business's current assets .",
    col3: false,
    buttons: {
      btn1: "APPLY NOW",
      btn2: "CHECK ELIGIBILITY",
    },
    textWrap: false,
    image: Image3,
   
    position: {
      right: '-2.375rem',
      bottom: ''
    },
   
  },
  {
    heading: "Business Loan",
    para: "Business loan is a financial product designed to provide funds for various business needs. You can apply for a business loan to support your company's growth,",
    col3: false,
    buttons: {
      btn1: "APPLY NOW",
      btn2: "CHECK ELIGIBILITY",
    },
    textWrap: false,
    image: Image2,
    
    position: {
      right: "-3.125rem",
      bottom: 0,
    },
   
  },
  {
    heading: "Secured Loan",
    para: "Mortgage loan is a secured loan taken for business expansion and working capital. The loan amount is based on the property's value and the borrower's credit profile.",
    col3: false,
    buttons: {
      btn1: "APPLY NOW",
      btn2: "CHECK ELIGIBILITY",
    },
    textWrap: false,
    image: Image5,
    
    position: { right: "-4.625rem", bottom: 0 },
    
  },
];

export default Data;
