import React from 'react'
import Image1 from "../assets/Section-2-Images/Group 14801.png";
import Image2 from "../assets/Section-2-Images/Group 14800.png";
import iconImage from "../assets/Section-2-Images/6319fd25a372293a7caf9e84_Marker.svg.png";
import Image3 from '../assets/Section-2-Images/Group 14594.png'
import Image4 from '../assets/Section-2-Images/Group 14601.png'

const FinancialSolutionsOverview = ({value}) => {
  return (
    <div className="container">
          <div className="row1">
            <div className="leftSide">
              <img src={Image1} alt="" />
            </div>
            <div className="rightSide">
              <div className="rightSide-header">
              <h1 className='rightSide-header-h1'>Streamline Supply Chain</h1>
              <p className='rightSide-header-description'>Unlock your cashflows by discounting your unpaid invoices </p>
              </div>
              <div className='rightSide-content'>
                <li className='rightSide-content-item item1'>
                  <img  src={iconImage} alt="" />
                  <p>No Collateral</p>
                </li>
                <li className='rightSide-content-item item2'>
                  <img  src={iconImage} alt="" />
                  <p>
                    Flexible discounting for SMEs at unbelievable interest
                    rates.
                  </p>
                </li>
                <li className='rightSide-content-item item3'>
                  <img  src={iconImage} alt="" />
                  <p>
                    Aquire new projects without having to worry about cashflows
                    .
                  </p>
                </li>
              </div>
            </div>
          </div>
          <div className="row2">
            <div className="leftSide">
              <h1 className='leftSide-header-h1'>Finance Your Purchase</h1>
              <p className='leftSide-header-p'>
                Is your OD exhausted and you are unable to meet your project
                obligations due to shortage of working capital ?
              </p>
              <div className='leftSide-content'>
                <li className='leftSide-content-item item1'>
                  <img src={iconImage} alt="" />
                  <p>
                    Get a revolving line of credit to purchase Raw Material
                    without Collateral
                  </p>
                </li>
                <li className='leftSide-content-item item2'>
                  <img src={iconImage} alt="" />
                  <p>
                    Flexible discounting for SMEs at unbelievable interest
                    rates.
                  </p>
                </li>
                
              </div>
            </div>
            <div className="rightSide">
              <img src={Image2} alt="" />
              
            </div>
          </div>
        </div>
  )
}

export default FinancialSolutionsOverview;
