import React, { useState } from 'react'
import TestimonialCard from '../TestimonialCard/TestimonialCard'
import Image3 from "../../assets/Images/Group 14602.png";
import vectorImage from '../../assets/Images/SVG.png'
import vectorImage2 from '../../assets/Images/Vector2.png'
import './loanOptionCard.css'
import ApplyButton from '../ApplyButton';
import GetStartedButton from '../GetStartedButton';
const LoanOptionCard = () => {
    const [index, setIndex] = useState(0);

    const tabsData = [
        {
          title: "Supply Chain Finance",
          head: 'Supply Chain Finance'
        },
        {
          title: "Business Loans",
          head: 'Business Loan'
        },
        {
          title: "LRD",
          head: 'Lease Rental Discounting'
        },
        {
          title: "Discounting",
          head: 'Bill Discounting'
        },
        {
          title: "Mortgage",
          head: 'Mortgage'
        },
        {
          title: "Channel",
          head: 'Channel Financing'
        },
        {
          title: "Overdraft",
          head: 'Over Draft'
        },
      ];
    
      const {head} = tabsData[index]
    
      const Styles = (i) => ({
        backgroundColor:
          index === i ? "rgba(0, 0, 0, 1)" : "rgba(249, 249, 249, 1)",
        color: index === i ? "white" : "black",
        border: index === i ? "1px solid rgba(0, 0, 0, 1)" : "none",
        transition: '0.5s all ease'
      });
  return (
    <div className="financingSolutionsContainer">
          <div className="container">
            <div className="head">
              <h1>Tailor-made Financing Solutions</h1>
              <p>for Every Business Need</p>
            </div>

            <div className="tabs">
              {tabsData.map((tab, i) => (
                <button
                key={i}
                  style={Styles(i)}
                  onClick={() => setIndex(i)}
                  className="tab"
                >
                  {tab.title}
                  
                </button>
                
              ))}
            </div>

            <div className="content1">
              <div className="column">
                <div className="icon">
                  <img src={Image3} alt="Factory Icon" />
                </div>
                <div className="columnContent">
                  <h2 className='columnContentHeader'>{head}</h2>
                  <div className='columnContentHeader-content'>
                    <li className='columnContentHeader-content-item'>
                      Top Choice for SMEs and Large Enterprises with annual
                      turnover {">"} 1 Cr
                    </li>
                    <li className='columnContentHeader-content-item'>
                      Apply if you want to gain financial flexibility with
                      access to extra funds when you need them.
                    </li>
                  </div>
                </div>
              </div>
              
            </div>
            <div className="buttons">
              <GetStartedButton vector={vectorImage}/>
             
              <ApplyButton vector={vectorImage2}/>
            </div>
            <TestimonialCard/>
          </div>
        </div>
  )
}

export default LoanOptionCard;
