import React, { useEffect, useState } from 'react'
import Image1 from '../../assets/Images/iPhone 14 Pro.png'
import Arrow from '../../assets/Images/Arrow 1.png'
import Image2 from '../../assets/Images/Property 1=Group 14662.png'
import Image3 from '../../assets/Images/Property 1=Group 14664.png'
import './howToApplyCard.css'
const data = [
  {
    img: Image1,
    height: '60.4375rem'
  },
  {
    img: Image2,
    height: '66.59rem '
  },
  {
    img: Image3,
    height: '60.8rem '
  }
]
const HowToApplyCard = () => {
  const [value, setValue] = useState(0)

  useEffect(() => {
    const intervalId = setInterval(() => {
      setValue((prev) => prev === 2 ? 0 : prev + 1)
      
    }, 3000);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <div className="howToApplyContainer">
      <div className="howToApplyContent">
        <div className="headerContent">
          <h1>How To Apply</h1>
          <p>
            Procure raw materials for your business at the right price with our
            working capital finance solution
          </p>
        </div>
        <div className="bodyContent">
          <div className="stepsContent">
            <div className="step step1">
              <div className="stepNumber">
                <p>1</p>
                <img src={Arrow} alt="" />
              </div>
              <div className="stepDetails">
                <p className="stepHeading">Check Eligibility</p>
                <p className="stepDescription">Check your loan eligibility</p>
              </div>
            </div>
            <div className="step step2">
              <div className="stepNumber">
                <p>2</p>
                <img src={Arrow} alt="" />
              </div>
              <div className="stepDetails">
                <p className="stepHeading">Fill Application</p>
                <p className="stepDescription">
                  Complete a 100% online application form
                </p>
              </div>
            </div>
            <div className="step step3">
              <div className="stepNumber">
                <p>3</p>
                <img src={Arrow} alt="" />
              </div>
              <div className="stepDetails">
                <p className="stepHeading">Get Approval</p>
                <p className="stepDescription">
                  We will evaluate your application and propose a fair sanction
                </p>
              </div>
            </div>
            <div className="step step4">
              <div className="stepNumber">
                <p>4</p>
              </div>
              <div className="stepDetails">
                <p className="stepHeading">Disbursement</p>
                <p className="stepDescription">
                  Get disbursement within 2 days
                </p>
              </div>
            </div>
          </div>
          <div className="imageSlider">
            {data.map((item, index) => (
              <div style={{
                
                opacity: value === index ? 1 : 0,
                transition: 'opacity 0.5s ease-in-out'
              }} className="phoneImage">
                <img
                // style={{height: item.height}}
                key={index}
                src={item.img}
                
                alt={`slider ${index}`}
              />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default HowToApplyCard
