import React, { useState } from "react";
import "./form.css";

const Form = () => {
  const initialFormData = {
    fullName: "",
    phoneNumber: "",
    email: "",
    company: "",
    address: "",
  };

  const [formData, setFormData] = useState(initialFormData);
const [disableSubmitButton, setDisableSubmitButton] = useState(false)
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setDisableSubmitButton(true)
    try {
      const contactResponse = await fetch(
        `https://vcare.bitrix24.in/rest/26/r0quo5nwb9nehwbv/crm.contact.add.json?FIELDS[NAME]=${formData.fullName}&FIELDS[COMPANY]=${formData.company}&FIELDS[EMAIL][0][VALUE]=${formData.email}&FIELDS[PHONE][0][VALUE]=${formData.phoneNumber}&FIELDS[ADDRESS]=${formData.address}`, 
        {
          method: 'GET',
        }
      );

      if (contactResponse.ok) {
        const contactData = await contactResponse.json();
        const contactId = contactData.result;

        const dealResponse = await fetch(
          `https://vcare.bitrix24.in/rest/26/kr7n27urxuxr9xie/crm.deal.add.json?FIELDS[TITLE]=Deal%20with%20${formData.fullName}&FIELDS[CONTACT_ID]=${contactId}`, 
          {
            method: 'GET',
          }
        );

        if (dealResponse.ok) {
          alert("Form and deal submitted successfully!");
          setFormData(initialFormData);
          setDisableSubmitButton(false)
        } else {
          console.error("Error creating deal:", dealResponse.statusText);
          alert("Form submission succeeded, but creating deal failed!");
        }
      } else {
        console.error("Error creating contact:", contactResponse.statusText);
        alert("Form submission failed!");
      }
    } catch (error) {
      console.error("Error:", error);
      alert("Form submission failed!");
    }
  };

  return (
    <div className="formContainer">
      <div className="formContent">
        <p className="formContent-header-p">Not Sure Where to Start?</p>
        <div className="form">
          <h2 className="form-header">Talk To Our Advisors Now</h2>
          <form className="formInput-list" onSubmit={handleSubmit}>
            <input
              className="input"
              type="text"
              name="fullName"
              id="fullName"
              placeholder="Your Full Name"
              required
              value={formData.fullName}
              onChange={handleChange}
            />
            <input
              className="input"
              type="number"
              name="phoneNumber"
              id="phoneNumber"
              placeholder="Phone Number"
              required
              minLength="10"
              value={formData.phoneNumber}
              onChange={handleChange}
            />
            <input
              className="input"
              type="email"
              name="email"
              id="email"
              required
              placeholder="Email ID"
              value={formData.email}
              onChange={handleChange}
            />
            <input
              className="input"
              type="text"
              name="company"
              id="company"
              placeholder="Company"
              value={formData.company}
              onChange={handleChange}
            />
            <input
              className="input"
              type="text"
              name="address"
              id="address"
              placeholder="Address"
              value={formData.address}
              onChange={handleChange}
            />
            <button disabled={disableSubmitButton} className="submitButton" type="submit">
              <span className="span submit-title">Submit</span> <span className="span arrow">&rarr;</span>
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Form;
