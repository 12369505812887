import React from "react";
import Image from "../../assets/Section-2-Images/Group 14704 (1).png";
import Image1 from "../../assets/Section-2-Images/Group 14703.png";
import "./financingOptions.css";
import { useNavigate } from "react-router-dom";
const FinancingOptions = ({ cards, mainHeading, mainParagraph, value }) => {
  const navigate = useNavigate();
  return (
    <div className={`financing-content ${mainHeading.replace(/ /g, "")}`}>
      <div className="heading-section">
        <h1 className="main-title">{mainHeading}</h1>
        <p className="main-description">{mainParagraph}</p>
      </div>
      {value === 5 && (
        <div className="businessLoanContent">
          <div>
            <p className="title">Unsecured Overdraft</p>
            <p className="description">
              An overdraft facility is a financial product that allows you to
              withdraw money from your savings or current account even if your
              account balance is zero. This feature is available through various
              private and public sector banks. An overdraft facility is a
              short-term loan that must be repaid within a defined tenure as
              stipulated by the lender. The lender imposes interest rates that
              the borrower must repay according to the bank's terms and
              conditions. For overdrafts, the interest rates provided by lenders
              are fixed and not floating.
            </p>
          </div>
        </div>
      )}
      {value === 6 && (
        <div className="securedLoanContent">
          <div>
            <p className="title">150% Mortgage Loan</p>
            <p className="description">
              The product offers a specialized mortgage loan tailored for SMEs,
              providing up to 150% of property value , Loan-to-Value (LTV) ratio
              based on their assets. This means SMEs can secure a loan amount
              exceeding the assessed value of their assets, leveraging
              additional capital for business expansion or operational needs. By
              utilizing their assets as collateral, SMEs gain access to
              significant funding at competitive interest rates and favorable
              repayment terms. This financial solution supports SMEs in
              optimizing their liquidity, pursuing growth initiatives, and
              enhancing financial resilience while leveraging their existing
              asset base.
            </p>
          </div>
          <div>
            <p className="title">Demand Loan</p>
            <p className="description">
              Also referred to as a Working Capital Loan (WCL), a demand loan is
              characterized by its flexible repayment terms, typically not
              fixed. Primarily intended for short-term business needs, it
              features a shorter and adaptable repayment period. Moreover, this
              loan type is extended exclusively to borrowers with a dependable
              income stream and a strong credit record.
            </p>
          </div>
        </div>
      )}
      <div
        style={{ width: value === 0 ? "100%" : "80%" }}
        className="card-grid"
      >
        {cards.map((cardData, index) => (
          <div key={index} className={`card card-${index + 1}`}>
            <h2>{cardData.head}</h2>
            {value !== 0 && <p className="description">{cardData.paragraph}</p>}
            {value === 0 && (
              <a
                onClick={() => navigate("/AboutUs")}
                className="readMore"
                href="#"
              >
                {cardData.paragraph}
              </a>
            )}
            {value !== 0 && <p className="semi-circle"></p>}
            <img className="mainImage" src={cardData.image} alt="" />
            {value === 0 && index === 2 && <div className="overlay"></div>}
            {value === 0 && index === 2 && (
              <img className="titleImage" src={Image1} />
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default FinancingOptions;
