import React from "react";
import officeImage from "../../assets/ContactUs Page Image/image 22.png";
import "./contactUs.css";
import Form from "../../components/Form/Form";
import Footer from "../../components/Footer/Footer";
import PagesTopSection from "../../components/PagesTopSection/PagesTopSection";

const ContactUs = ({
  value,
  setValue,
  showProductMenu,
  setShowProductMenu,
  topSection = {
    image: null,
    title: "CONTACT US",
    description: "V Care Financial Services",
  },
}) => {
  const contactDetails = [
    {
      title: "Phone No",
      items: [
        { label: "+91 9343834410", href: "tel:+919343834410" },
        { label: "+91 7829972813", href: "tel:+917829972813" },
      ],
    },
    {
      title: "For Sales",
      items: [{ label: "info@vcarefinancials.in", href: "mailto:info@vcarefinancials.in" }],
    },
    {
      title: "For Enquiries",
      items: [{ label: "info@vcarefinancials.in", href: "mailto:info@vcarefinancials.in" }],
    },
    {
      title: "For Support",
      items: [{ label: "info@vcarefinancials.in", href: "mailto:info@vcarefinancials.in" }],
    },
  ];

  return (
    <div className="contactUsContainer">
      <div className="containerContent">
        <PagesTopSection
          value={value}
          setValue={setValue}
          showProductMenu={showProductMenu}
          setShowProductMenu={setShowProductMenu}
          topSection={topSection}
        />

        <div className="officeSection">
          <div className="row">
            <div className="officeAbout">
              <div className="leftContent">
                <h3 className="leftContent-header-h3">Bangalore Office</h3>
                <p className="leftContent-header-p">
                  Purchasing Finance is the working capital financing solution
                  provided to SMEs. Due to a lack of cash flow, SMEs are forced
                  to buy raw materials at higher prices and tend to lose
                  opportunities from reputable customers. In such situations,
                  SMEs can consider purchase finance solution to bridge the
                  working capital gap.
                </p>
                <address className="address">
                  Address: No 66, Situated at 27th Cross, 13th Main Road 4th
                  Block East, Jayanagar,
                </address>
              </div>
              <div className="officeImage">
                <img src={officeImage} alt="Bangalore Office" />
              </div>
            </div>
            <div className="contactDetails">
              <h3 className="contactDetails-header-h3">Reach Out To Us</h3>
              <div className="details">
                <div className="details-list">
                  {contactDetails.map((detail, index) => (
                    <div key={index} className={`details-list-item item${index + 1}`}>
                      <p className="detailTitle">{detail.title}</p>
                      {detail.items.map((item, subIndex) => (
                        <p key={subIndex}>
                          <a href={item.href} className="contactLink">
                            {item.label}
                          </a>
                        </p>
                      ))}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="formSection">
          <Form />
        </div>
      </div>
      <Footer setValue={setValue} />
    </div>
  );
};

export default ContactUs;
