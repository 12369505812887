import React from 'react'

const GetStartedButton = ({vector}) => {
  return (
    <div className="getStarted">
          <p className="started">Get Started</p>
          {vector && <img src={vector} alt="" />}
    </div>
  )
}

export default GetStartedButton;
