import React from 'react'

const ApplyButton = ({vector}) => {
  return (
    <a  style={{textDecoration: 'none'}} href='https://b24-ynfsx4.bitrix24.site/crm_form_nprvo/' target='_blank' className="applyButton">
          <p className="apply">apply now</p>
          {vector && <img src={vector} alt="" />}
    </a>
  )
}

export default ApplyButton
