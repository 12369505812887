import React from 'react'
import vector1 from '../../assets/NavbarIcons/Vector.png'
import vector2 from '../../assets/NavbarIcons/Vector (2).png'
import vector3 from '../../assets/NavbarIcons/Vector (3).png'
import vector4 from '../../assets/NavbarIcons/Vector (4).png'
import vector5 from '../../assets/NavbarIcons/Group.png'
import vector6 from '../../assets/NavbarIcons/Group 14707.png'
import './dropDownMenu.css'
import { useNavigate } from 'react-router-dom'
const DropDownMenu = ({showProductMenu, setShowProductMenu, setValue}) => {
    const Data = [
        { text: "Purchase Finance", value: 1, securedCapital: false, image: vector1 },
        { text: "Bill Discounting", value: 3, securedCapital: false, image: vector6 },
        { text: "Working Capital", value: 4, securedCapital: true, image: vector2 },
        { text: "Business Loan", value: 5, securedCapital: false, image: vector3 },
        { text: "Term Loan", value: 6, securedCapital: true, image: vector4 },
        { text: "Lease Discounting", value: 2, securedCapital: true, image: vector5 },
    
      ];

      const navigate = useNavigate()

      const handleClick = (value, pageName) => {
        setValue(value);
        setShowProductMenu(false);
        navigate('/')
        
      }
  return (
    <div
                
                className={`dropDown ${showProductMenu ? 'show' : ''}`}
              >
                <div className="unsecuredCapital">
                <p>Unsecured Capital</p>
                <div className="capitalNames" id='capitalNames'>
                {Data.filter((item) => item.securedCapital === false).map((item, index) => (
                  <div
                    key={index}
                    onClick={() => handleClick(item.value, item.text)}
                  >
                    <div className="icon">
                    <div className="iconImage">
                    <img src={item.image} alt="" />
                    </div>
                    </div>
                    <p>{item.text}</p>
                  </div>
                ))}
                </div>
                </div>
                <div className="securedCapital">
                  <p>Secured Capital</p>
                <div className="capitalNames" id='capitalNames'>
                {Data.filter((item) => item.securedCapital === true).map((item, index) => (
                  <div
                    key={index}
                    onClick={() => {
                      setValue(item.value);
                      navigate('/')
                      setShowProductMenu(false);
                    }}
                  >
                    <div className="icon">
                    <div className="iconImage">
                    <img src={item.image} alt="" />
                    </div>
                    </div>
                    <p>{item.text}</p>
                  </div>
                ))}
                </div>
                </div>
              </div>
  )
}

export default DropDownMenu
