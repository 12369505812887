import Image1 from "../../assets/Section-2-Images/openart-image_Plqt-COQ_1718460609196_raw-removebg-preview 1.png";
import Image2 from "../../assets/Section-2-Images/Frame 44.png";
import Image3 from "../../assets/Section-2-Images/openart-image_naBlmHFm_1719249010788_raw-removebg-preview 1.png";
import Image4 from '../../assets/Section-2-Images/CashDiscount.png'
import Image5 from '../../assets/Section-2-Images/Group 14620 (1).png'
import Image6 from '../../assets/Section-2-Images/Component 18.png'
import Image7 from '../../assets/Section-2-Images/Component 16.png'
import Image8 from '../../assets/Section-2-Images/Frame 37.png'
import Image9 from '../../assets/Section-2-Images/Component 19 (1).png'
import Image10 from '../../assets/Section-2-Images/Group 14776.png'
import Image11 from '../../assets/Section-2-Images/Group 14780.png'
import Image12 from '../../assets/Section-2-Images/Group 14774.png'
import Image13 from '../../assets/Section-2-Images/Group 14779.png'
import Image14 from '../../assets/Section-2-Images/Group 14781.png'
import Image15 from '../../assets/Section-2-Images/Group 14782.png'
import Image16 from '../../assets/Section-2-Images/Group 14699.png'
import Image17 from '../../assets/Section-2-Images/Group 14783.png'
const Data = () => [
  {
    mainHeading: "What Makes Us Different",
    mainParagraph: "V Care’s industry differentiating approach",
    cards: [
      {
        head: "Customised & Flexible Solutions",
        paragraph: "Read More",
        image: Image1,
        
      },
      {
        head: "Powered By Technology",
        paragraph: "Read More",
        image: Image2,
        
      },
      {
        head: "Prompt and Responsive Customer Service",
        paragraph: "Read More",
        image: Image3,
        
      },
    ],
  },
  {
    mainHeading: "Purchase Finance",
    mainParagraph:
      "Purchasing Finance is the working capital financing solution provided to SMEs. Due to a lack of cash flow, SMEs are forced to buy raw materials at higher prices and tend to lose opportunities from reputable customers. In such situations, SMEs can consider purchase finance solution to bridge the working capital gap. It will help the SMEs get cash discounts on raw materials by making upfront payment to suppliers and also aids in the growth of the enterprise both on the bottom line and on the top line. Purchase financing solution enables enterprises to meet short term finance needs.",
    cards: [
      {
        head: "Low Cost Procurement",
        paragraph:
          "Pay your suppliers upfront and get raw materials at the best rates",
        image: Image4,
        
      },
      {
        head: "Unsecured Line Of Credit",
        paragraph:
          "The unsecured working capital does not require land, property, BG or any other collateral",
        image: Image5,
        
      },
      {
        head: "Interest As Per Use",
        paragraph:
          "The interest rate would be charged only for the exact amount and duration of use",
        image: Image10,
        
      },
      {
        head: "Revolving Credit Line",
        paragraph: "60-120 days rotation cycle to meet working capital needs",
        image: Image11,
        
      },
    ],
  },
  {
    mainHeading: "Lease Rental Discount",
    mainParagraph:
      "Purchasing Finance is the working capital financing solution provided to SMEs. Due to a lack of cash flow, SMEs are forced to buy raw materials at higher prices and tend to lose opportunities from reputable customers. In such situations, SMEs can consider purchase finance solution to bridge the working capital gap. It will help the SMEs get cash discounts on raw materials by making upfront payment to suppliers and also aids in the growth of the enterprise both on the bottom line and on the top line. Purchase financing solution enables enterprises to meet short term finance needs.",
    cards: [
      {
        head: "Low Cost Procurement",
        paragraph:
          "Pay your suppliers upfront and get raw materials at the best rates",
        image: Image8,
        
      },
      {
        head: "Unsecured Line Of Credit",
        paragraph:
          "The unsecured working capital does not require land, property, BG or any other collateral",
        image: Image7,
        
      },
      {
        head: "Interest As Per Use",
        paragraph:
          "The interest rate would be charged only for the exact amount and duration of use",
        image: Image6,
       
      },
      {
        head: "Revolving Credit Line",
        paragraph: "60-120 days rotation cycle to meet working capital needs",
        image: Image12,
        
      },
    ],
  },
  {
    mainHeading: "Invoice Discounting",
    mainParagraph:
      "Invoice discounting is a financial solution designed to help businesses manage their cash flow by providing short-term loans against unpaid invoices. When a business issues an invoice to a customer with a future payment date, it can use invoice discounting to obtain immediate funds. The loan amount is determined by the value of these outstanding invoices. This solution is particularly beneficial for businesses that experience delays in payment from their clients, as it ensures a steady cash flow. Invoice discounting allows businesses to cover operational expenses, invest in growth opportunities, and avoid cash flow shortages. By leveraging their accounts receivable, companies can maintain financial stability and continue their operations smoothly.",
    cards: [
      {
        head: "Advance Payments",
        paragraph:
          "Receive early payment on your invoices, reducing the payment cycle",
        image: Image13,
       
      },
      {
        head: "Unsecured Line Of Credit",
        paragraph:
          "The unsecured working capital does not require land, property, BG or any other collateral",
        image: Image5,
       
      },
      {
        head: "Interest As Per Use",
        paragraph:
          "The interest rate would be charged only for the exact amount and duration of use",
        image: Image10,
       
      },
      {
        head: "Revolving Credit Line",
        paragraph: "60-120 days rotation cycle to meet working capital needs",
        image: Image11,
       
      },
    ],
  },
  {
    mainHeading: "Working Capital",
    mainParagraph:
      "Purchasing Finance is the working capital financing solution provided to SMEs. Due to a lack of cash flow, SMEs are forced to buy raw materials at higher prices and tend to lose opportunities from reputable customers. In such situations, SMEs can consider purchase finance solution to bridge the working capital gap. It will help the SMEs get cash discounts on raw materials by making upfront payment to suppliers and also aids in the growth of the enterprise both on the bottom line and on the top line. Purchase financing solution enables enterprises to meet short term finance needs.",
    cards: [
      {
        head: "Low Cost Procurement",
        paragraph:
          "Pay your suppliers upfront and get raw materials at the best rates",
        image: Image6,
       
      },
      {
        head: "Unsecured Line Of Credit",
        paragraph:
          "The unsecured working capital does not require land, property, BG or any other collateral",
        image: Image8,
        
      },
      {
        head: "Interest As Per Use",
        paragraph:
          "The interest rate would be charged only for the exact amount and duration of use",
        image: Image10,
        
      },
      {
        head: "Revolving Credit Line",
        paragraph: "60-120 days rotation cycle to meet working capital needs",
        image: Image11,
       
      },
    ],
  },
  {
    mainHeading: "Business Loan",
    mainParagraph:
      "Purchasing Finance is the working capital financing solution provided to SMEs. Due to a lack of cash flow, SMEs are forced to buy raw materials at higher prices and tend to lose opportunities from reputable customers. In such situations, SMEs can consider purchase finance solution to bridge the working capital gap. It will help the SMEs get cash discounts on raw materials by making upfront payment to suppliers and also aids in the growth of the enterprise both on the bottom line and on the top line. Purchase financing solution enables enterprises to meet short term finance needs.",
    cards: [
      {
        head: "Quick and Easy Access",
        paragraph:
          "Get business loan within 7 working days and with minimum dcoumentation",
        image: Image6,
     
      },
      {
        head: "Unsecured Line Of Credit",
        paragraph:
          "The unsecured working capital does not require land, property, BG or any other collateral",
        image: Image5,
       
      },
      {
        head: "Competitive Rates",
        paragraph:
          "The interest rate for business loan is less than private finance with more duration ",
        image: Image14,
      
      },
      {
        head: "Without Any Specifications",
        paragraph:
          "Business loans dont come with any specification such as only invoices and etc",
        image: Image15,
       
      },
    ],
  },
  {
    mainHeading: "Secured Loan",
    mainParagraph:
      "A secured term loan for MSMEs is a financial product designed to provide funding to small and medium-sized enterprises (MSMEs) while requiring collateral as security. These loans offer businesses access to substantial amounts of capital with longer repayment periods and competitive interest rates. By pledging assets such as property or equipment as security, MSMEs can qualify for secured term loans that support various business needs, including expansion, equipment purchase, working capital management, and infrastructure development. Secured term loans provide financial stability and flexibility, enabling MSMEs to pursue growth opportunities, enhance productivity, and manage cash flow effectively.",
    cards: [
      {
        head: "Low Cost Procurement",
        paragraph:
          "Pay your suppliers upfront and get raw materials at the best rates",
        image: Image9,
        
      },
      {
        head: "Unsecured Line Of Credit",
        paragraph:
          "The unsecured working capital does not require land, property, BG or any other collateral",
        image: Image8,
       
      },
      {
        head: "Interest As Per Use",
        paragraph:
          "The interest rate would be charged only for the exact amount and duration of use",
        image: Image16,
        
      },
      {
        head: "Revolving Credit Line",
        paragraph: "60-120 days rotation cycle to meet working capital needs",
        image: Image17,
       
      },
    ],
  },
];

export default Data;
