import React, { useState, useEffect, useRef } from "react";
import "./Navbar.css";
import vector from "../../assets/Images/Vector.png";
import logo from "../../assets/Images/image-removebg-preview-72 1.png";

import { RxCaretDown } from "react-icons/rx";
import DropDownMenu from "../DropDownMenu/DropDownMenu";
import { useNavigate } from "react-router-dom";
import ApplyButton from "../ApplyButton";

const Navbar = ({
  setValue,
  value,
  showProductMenu,
  setShowProductMenu,
}) => {
  const navigate = useNavigate();
  const dropdownRef = useRef(null);

  const handleClick = () => {
    setShowProductMenu((prev) => !prev);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setShowProductMenu(false);
    }
  };

  useEffect(() => {
    if (showProductMenu) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showProductMenu]);

  return (
    <nav 
    className="navbarContainer"
    
    >
      <div
        
        onClick={() => {
          navigate("/");
          setValue(0);
        }}
        className="logo"
      >
        <img src={logo} alt="" />
      </div>

      <div className="link">
        <div className="link-list">
          <li
            style={{ color: showProductMenu ? "#FF9F60" : "black" }}
            className="nav nav1"
          >
            <div className="product" ref={dropdownRef}>
              <p id="Products" onClick={handleClick}>
                Products{" "}
                <span className="caretDown">
                  <RxCaretDown />
                </span>
              </p>

              {showProductMenu && (
                <DropDownMenu
                  setShowProductMenu={setShowProductMenu}
                  setValue={setValue}
                  showProductMenu={showProductMenu}
                />
              )}
            </div>
          </li>
          <li
            id="aboutUs"
            onClick={() => navigate("/aboutUs")}
            className="nav nav2"
          >
            About Us
          </li>

          <li
            id="contactUs"
            onClick={() => {
              navigate("/contactUs");
              setShowProductMenu(false);
            }}
            className="nav nav4"
          >
            Contact Us
          </li>
        </div>
        
        <ApplyButton vector={vector}/>
      </div>
    </nav>
  );
};

export default Navbar;
