import { useState } from "react";
import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import HomePage from "./components/HomePage";
import ContactUs from "./Pages/cotactUs/ContactUs";
import AboutUs from "./Pages/aboutUs/AboutUs";
import ReactGA from 'react-ga4'



function App() {
  
  const [value, setValue] = useState(0);
  const [showProductMenu, setShowProductMenu] = useState(false);
  const TrackingID = 'G-VMFVBQX9Q4'
  
  
  ReactGA.initialize(TrackingID, { debug_mode: true });
  
  return (
    <Router>
      <Routes>
        <Route
          path="/"
          element={
            <HomePage
              value={value}
              setValue={setValue}
              showProductMenu={showProductMenu}
              setShowProductMenu={setShowProductMenu}
            />
          }
        />
        <Route
          path="/ContactUs"
          element={<ContactUs 
          value={value} setValue={setValue} 
          showProductMenu={showProductMenu}
          setShowProductMenu={setShowProductMenu}/>}
          
        />
        <Route
          path="/AboutUs"
          element={<AboutUs 
          value={value} setValue={setValue} 
          showProductMenu={showProductMenu}
          setShowProductMenu={setShowProductMenu}/>}
          
        />
      </Routes>
    </Router>
  );
}

export default App;
