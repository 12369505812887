import React, { useState } from "react";
import "./section2.css";
import Data from "./Data";


import LoanOptionCard from "../LoanOptionCard/LoanOptionCard";
import FinancingOptions from "../FinancingOptions/FinancingOptions";
import FinancialSolutionsOverview from "../FinancialSolutionsOverview";


const Section2 = ({ value }) => {
  const [section2Data] = useState(Data);

  const { cards, mainHeading, mainParagraph } = section2Data[value];


  return (
    <section className="section-2-Container">
      {value === 0 && (
        <FinancialSolutionsOverview value={value}/>
      )}
      <FinancingOptions cards={cards} mainHeading={mainHeading} mainParagraph={mainParagraph} value={value} />
      
    </section>
  );
};

export default Section2;
