import React from 'react';
import './requiredDocuments.css'
import Image16 from '../../assets/RequiredDocumentsImages/Group 14791.png'
import Image17 from '../../assets/RequiredDocumentsImages/Group 14792.png'
import Image18 from '../../assets/RequiredDocumentsImages/Group 14793.png'
import Image19 from '../../assets/RequiredDocumentsImages/Group 14794.png'
const requiredDocuments = [
  {
      title: 'KYC Details',
      image: Image16
   },
   {
      title: '3 Years ITR',
      image: Image17

   },
   {
      title: 'Bank Statements',
      image: Image18
   },
   {
      title: 'GST 3B',
      image: Image19
   }
];



const RequiredDocuments = () => {
   return (
      <div className="required-documents">
        <div className="required-documents-head-content">
            <h1 className='required-documents-header'>Required Documents</h1>
            <p className='required-documents-description'>Submit these documents to get faster approval</p>
        </div>
        <div className="required-documents-cards">
         {requiredDocuments.map((document, index) => (
            <div key={index} className="document-card">
               <h3 className='document-card-title'>{document.title}</h3>
            <div className="document-card-image">
               <img src={document.image} alt="" />
            </div>
         </div>
         ))}
         </div>
      </div>
   );
}

export default RequiredDocuments;