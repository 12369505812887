const Data = () => [
  {
    head: "UNSECURED CAPITAL",
    links: [
      {
        link: "Purchase Financing",
        value: 1
      },
      {
        link: "Invoice Discounting",
        value: 3
      },
      {
        link: "Business Loan",
        value: 5
      },
      {
        link: "Unsecured Overdraft",
        value: 5
      },
    ],
  },
  {
    head: "SECURED CAPITAL",
    links: [
      {
        link: "Term Loan",
        value: null
      },
      {
        link: "Working Capital",
        value: 4
      },
      {
        link: "Lease Rental Discounting",
        value: 2
      },
    ],
  },
  {
    head: "COMPANY",
    links: [
      {
        link: "About Us",
        value: null
      },
    ],
  },
  {
    head: "CONTACT US",
    links: [
      {
        link: "Contact Us",
        value: null
      },
    ],
  },
  {
    head: "POLICY",
    links: [
      {
        link: "Terms of Use",
        value: null
      },
      {
        link: "Privacy Policy",
        value: null

      },
      {
        link: "Fraud Prevention Policy",
        value: null

      },
    ],
  },
];

export default Data;
