import React, { useState } from "react";
import "./footer.css";
import Data from "./FooterData";
import { useNavigate } from "react-router-dom";

const Footer = ({ setValue }) => {
  const [footerData] = useState(Data);
  const navigate = useNavigate();

  const handleClick = (link, value) => {
    navigate(
      link === "About Us" || link === "Contact Us"
        ? `/${link.replace(" ", "")}`
        : "/"
    );
    setValue((prev) => (value ? (prev = value) : prev));
  };
  return (
    <footer className="footer-container">
      <div className="footer">
        <div className="footer-column1">
          {footerData?.map((data, index) => (
            <div key={index} className={`footer-row ${data.head}`}>
              <h3 className="footer-head">{data.head}</h3>
              <div className="footer-links" id="footer-links">
                {data?.links.map((link, i) => (
                  <a
                    key={i}
                    style={{
                      color:
                        index === 0 || index === 1
                          ? "rgba(51, 65, 85, 1)"
                          : "rgba(100, 116, 139, 1)",
                    }}
                    onClick={() => handleClick(link.link, link.value)}
                    className="footer-link"
                  href="#">
                    {link.link}
                  </a>
                ))}
              </div>
            </div>
          ))}
        </div>
       
      </div>
    </footer>
  );
};

export default Footer;
