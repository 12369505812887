import React, { useEffect } from 'react'
import Section from './Section1Content/Section'
import Section3 from './Section3Content/Section3'
import Footer from './Footer/Footer'
import Section2 from './Section2Content/Section2'
import RequiredDocuments from './RequiredDocumentsSection/RequiredDocuments'
import ReactGA from 'react-ga4'
const HomePage = ({value, setValue, showProductMenu, setShowProductMenu}) => {
useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search });
  }, []);
  return (
    <div className='homePageContainer'>
      <Section setValue={setValue} value={value} showProductMenu={showProductMenu} 
      setShowProductMenu={setShowProductMenu}/>
      <Section2 value={value}/>
      <Section3 value={value}/>
      {value !== 0 && <RequiredDocuments/>}
      <Footer setValue={setValue}/>
    </div>
  )
}

export default HomePage;
