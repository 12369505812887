import React from "react";
import HowToApplyCard from "../HowToApplyCard/HowToApplyCard";
import LoanOptionCard from "../LoanOptionCard/LoanOptionCard";

const Section3 = ({value}) => {

  

  return (

    <>
    {value !== 0 && <HowToApplyCard/>}
    {value === 0 && <LoanOptionCard/>}
    </>
    
  );
};

export default Section3;