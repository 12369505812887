import React from 'react'
import Image1 from '../../assets/Images/Vector (1).png'
import Image2 from '../../assets/Images/Vector (2).png'
import Image3 from '../../assets/Images/Vector (3).png'
import Image4 from '../../assets/Images/Vector (4).png'
const InformationBox = () => {

    const Data = [{
        icon: Image4,
        title1: '18+ Years',
        title2: 'of Excellence'

    },{
        icon: Image3,
        title1: '500+',
        title2: 'Companies Empowered'

    },{
        icon: Image2,
        title1: '1000+ Cr',
        title2: 'Disbursed Till Date'

    },{
        icon: Image1,
        title1: '100+',
        title2: 'Partners'

    }]
  return (
    <div className="content">

        {Data.map((item) => (
            <div className="link link1">
            <div className="icon">
                <img src={item.icon} alt="" />
            </div>
            <div className="about">
                <p>{item.title1}</p>
                <p>{item.title2}</p>
            </div>
        </div>
        ))}
                  
    </div>
  )
}

export default InformationBox
