import React from "react";
import TestimonialImage from "../../assets/Images/Figure → testimonial-3 (2).png";
import TestimonialCompanyIcon from "../../assets/Images/SVG 3.png";
import './testimonialCard.css'
const TestimonialCard = () => {
  return (
    <div className="testimonialCardContainer">
      <div className="testimonial-container">
      <div className="testimonial-head">
        <h1 className="testimonial-head-h1">Your Trust, Our Commitment</h1>
        <p className="testimonial-head-p">Customer experiences that define us</p>
      </div>
      <div className="testimonial-card">
          <img
            src={TestimonialImage}
            alt="Testimonial Image"
            className="testimonial-image"
          />
        <div className="testimonial-content">
          <div className="testimonial-company">
            <img src={TestimonialCompanyIcon} alt="" />
            <span className="testimonial-company-name">
              Kram Infracon Pvt Ltd
            </span>
          </div>
          <h3 className="testimonial-heading">
            Got Our <span style={{color: 'rgba(230, 102, 82, 1)', width: 'max-content'}}>Purchase Financing Services</span> within 48 Hours of Online
            Application
          </h3>
          <p className="testimonial-quote">
            V Care's working capital solutions have helped me to increase my
            liquidity, which has in turn helped growing my business and better
            profit margins because I was able to improve my cash flow
            management. Would definitely recommend V Care.
          </p>
          <div className="testimonial-navigation">
            <button
              className="testimonial-navigation-button"
              aria-label="Previous Testimonial"
            >
              &#10229;
            </button>
            <button
              className="testimonial-navigation-button"
              aria-label="Next Testimonial"
            >
              &#10230;
            </button>
          </div>
        </div>
      </div>
    </div>
    </div>
  );
};

export default TestimonialCard;
