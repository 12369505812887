import React from "react";
import Image7 from "../../assets/Images/Group 2.png";
import { FaRegCheckCircle } from "react-icons/fa";

const boxData = [
  {
    limit: "1 Crore Limit",
    title: "Bill Discounting",
    center: false,
    icon: <FaRegCheckCircle />,
  },
  {
    limit: "1 Crore Limit",
    title: "Bill Discounting",
    center: false,
    icon: <FaRegCheckCircle />,
  },
  {
    limit: "5 Crore Limit",
    title: "Project Finance",
    center: false,
    icon: <FaRegCheckCircle />,
  },
  {
    limit: "50 Crore Limit",
    title: "Working Capital",
    center: false,
    icon: <FaRegCheckCircle />,
  },
  {
    limit: "50 Lakh Limit",
    title: "Purchase Finance",
    center: false,
    icon: <FaRegCheckCircle />,
  },
  {
    limit: "Total Limits Approved",
    title: "56.5 Crore",
    center: true,
    icon: "",
  },
];
const SectionRightSideContent = ({ currentValue, images, currentIndex}) => {
  const { limit, title, center, icon } = boxData[currentValue];

  const currentImage1 = images[currentIndex];
  const currentImage2 = images[(currentIndex + 1) % images.length];
  const currentImage3 = images[(currentIndex + 2) % images.length];
  const currentImage4 = images[(currentIndex + 3) % images.length];

  return (
    <div className="imageContainer">
      <div className="box">
        <p className="icon">{icon}</p>
        <div style={{ alignItems: center ? "center" : "" }} className="content">
          <p className="limit">{limit}</p>
          <p style={{ color: center ? "#FF944E" : "white" }} className="title">
            {title}
          </p>
        </div>
      </div>
      <div className="image1">
        <img src={currentImage1.src} alt="" />
        <p className="imageTitle">{currentImage1.title}</p>
      </div>
      <div className="image2">
        <img src={currentImage2.src} alt="" />
        <p className="imageTitle">{currentImage2.title}</p>

      </div>
      <div className="image3">
        <img src={currentImage3.src} alt="" />
        <p className="imageTitle">{currentImage3.title}</p>

      </div>
      <div className="image4">
        <img src={currentImage4.src} alt="" />
        <p className="imageTitle">{currentImage4.title}</p>

      </div>
      <p className="ellipse1"></p>
      <p className="ellipse2"></p>
      <div className="circle-mask">
        <div className="image">
          <img src={Image7} alt="Image" />
        </div>
      </div>
    </div>
  );
};

export default SectionRightSideContent;
